// @ts-ignore
window.global = window;

(async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        '/firebase-messaging-sw.js',
        {
          type: 'module',
        },
      );
      console.log({ registration });
      window.workerRegistration = registration;
    } catch (error) {
      console.error(error);
    }
  }
  import('./polyfills/polyfills').then(() => import('./index'));
})();

